*{
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif; */
}

a{
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

body{
  color: #000933;
}
img{
  max-width: 100%;
}
main{
  padding: 0px 0px;
  max-width: 1600px;
  margin: 0 auto;
  background-color: #FFFFFF;
}

.home-main {
  padding: 0px 10px;
}

.blog-main {
  padding: 0px 10px;
}

.about-main {
  padding: 0px 10px;
}

.methodology-main {
  padding: 0px 10px;
}

.collegepage-main {
  padding: 0px 10px;
}

.login-main {
  padding: 0px 10px;
}

.register-main {
  padding: 0px 10px;
}

.account-main {
  padding: 0px 10px;
}

.pp-main {
  padding: 0px 10px;
}

.clb-main {
  background-color: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 20px;
  max-width: 700px;
}

.terms-main {
  padding: 0px 10px;
}

.fos-main {
  padding: 0px 10px;
}

.heatmap-main {
  padding: 0px 10px;
}

.dashboard-main {
  padding: 0px 10px;
}

.collegebudgeting-main {
  padding: 10px 10px;
  background-color: #f4f4f4;
}

.ad-container {
  margin-top: 10px;
}

.major-main {
  padding: 0px 10px;
  max-width: 900px;
  margin: 0 auto;
}

header{
  display:flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 10px;
  align-items: center;
  position: sticky; /* Added line */
  top: 0; /* Sets the top position of the sticky header */
  z-index: 10000;
  background-color: #FFFFFF;
  height: 60px;
  width: 100%;
}

header a.logo{
  display:flex;
  align-items: left;
}

.logo svg{
  width: 200px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Removes any extra space around the SVG */
  margin-left: 10px;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-register {
  background-color: #05BFDD; /* Vibrant red */
  color: #000933; /* White text */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners */
  padding: 8px 12px; /* Space around text */
  font-size: 14px; /* Readable font size */
  font-weight: bold; /* Emphasize button */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Smooth hover effect */
}

/* Hover and active states for added interactivity */
.header-register:hover {
  background-color: #0498B2; /* Slightly darker red */
  transform: scale(1.05); /* Slightly enlarges on hover */
}

.hamburger {
  color: #000933; /* Navy */
  border: none;
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  font-weight: bold;
  padding: 10px 15px;
}


/* header nav{
  display:flex;
  gap: 15px;
} */

div.college{
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
  div.college{
    grid-template-columns: 0.8fr 1.2fr;
  }
}

div.college div.collegedesc h2{
  margin:0;
  font-size: 1.6rem;
}

div.college div.collegedesc a{
  text-decoration: none;
  color: inherit;
}

div.college p.info{
  margin:6px 0;
  color: #888;
  font-size:.7rem;
  font-weight:bold;
  display: flex;
  gap: 10px;
}

div.college p.info a.author{
  color:#333;
}

/* div.college div.collegedesc p.citystate{
  font-size: 0.9rem;
  margin: 0px;
  color: #333;
  display: flex;
  gap: 10px;
} */

div.college div.collegedesc p.summary {
  margin: 6px 0;
  line-height: 1.4rem;
}

form.login, form.register{
  max-width: 400px;
  margin: 0 auto;
}

input {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  height: 50px;
  font-size:larger;
}

button{
  width: 100%;
  display: block;
  border-radius: 5px;
  padding: 7px 0;
}

form.login h1, form.register h1{
  text-align: center;
}

div.post-page div.image{
  max-height: 300px;
  display: flex;
  overflow:hidden;
}

div.post-page div.image img{
  object-fit: cover;
  object-position: center;
  width: 100%;
}

div.post-page a{
  color:#333;
  text-decoration: underline;
}

div.post-page h1{
  margin: 10px 0 5px;
}

div.post-page time{
  display: block;
  font-size: .8rem;
  color:#aaa;
}

div.post-page div.author{
  margin-bottom: 20px;
  font-size: .8rem;
  font-weight: bold;
}

div.post-page div.content p{
  line-height: 1.6rem;
}

div.post-page a.edit-btn{
  background-color: #333;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color:white;
  padding:15px 30px;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 20px;
  font-weight: bold;
}

a.svg-editor svg{
  height: 24px;
}

.college-rankings-container {
  max-width: 100%;
  margin: 10px auto;
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-around;
  grid-gap: 10px;
  padding: 0px 10px;
}

.container {
  max-width: 1200px;
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-around;
  grid-gap: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 100%; /* 10px for the margin between cards */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 0px;
  padding: 15px;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.uni-card {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 450px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.uni-location-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.uni-location {
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 0.9rem;
  margin-bottom: 2px;
}

.container .card .card-top {
  display: flex;
  align-items: center; /* This will vertically center the items in the flex container */
  justify-content: space-between; /* This will place maximum space between the two items, you can adjust as needed */
  margin-bottom: 0px;
}

.container .card .card-top .card-ranking {
  margin-right: 10px; /* Adjust the margin as needed for spacing between the text and button */
}

.card-plus {
  width: 90px; /* Size of the button */
  height: 25px; /* Size of the button */
  border-radius: 20px; /* Makes the button round */
  font-size: 15px; /* Size of the plus sign */
  font-weight: bold;
  text-align: center; /* Centers the plus sign horizontally */
  padding: 0; /* Removes any default padding */
  border: 0px solid #000933; /* Optional: adds a border to the button */
  color: #000933; /* Color of the plus sign */
  background-color: #05BFDD;
  cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.card-plus::before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card-plus.added {
  background-color: #6e46c1;
  color: #FFF;
}

/* Media query for smaller screens */
@media (max-width: 960px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr)); /* Two cards per row */
  }
}

/* Media query for even smaller screens */
@media (max-width: 640px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)); /* One card per row */
  }
}


.card-college {
  text-decoration: none;
  color: inherit;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.card-location {
  text-align: left;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.card-ranking {
  text-align: left;
  font-weight:bold;
  margin-bottom: 0px;
}

.card-divide {
  height: 1px;
  background-color: #ccc;
  margin: 15px 0;
}

/* .calculation {
  margin-top: 15px;
  font-weight: bold;
  border-radius: 4px;
} */

/* .calculation-title {
  text-align: left;
} */

.calculation2 {
  margin-top: auto;
  position: relative;
  font-weight: bold;
  background-color: #000933;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}

.calculation2-header {
  display: flex;
 /*  justify-content: space-between; */ /* Aligns children (title and image) on opposite ends */
  align-items: center; /* Vertically centers children */

}
.brandmark {
  position:absolute;
  bottom: 18px;
  right: 10px;
  /* Adjust width and height as needed */
  width: 45px; /* Example width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
}

.calculation-title2 {
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 12px;
  margin-left: 16px;
  margin-top: 12px;
  font-size: 18px;
}

.line {
  height: 1px;
  background-color: #ccc;
  margin: 5px 0;
}

/* .item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  margin-left: 5px;
  text-align: left;
} */

/* .label {
  flex: 1.5;
  font-weight: normal;
  text-align: left;
} */

/* .value {
  margin-right: auto;
  text-align: right;
} */

.valuation-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; 
}

.valuation {
  margin-left: 16px;
  margin-right: 16px;
  color: #eee;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex-grow: 1;
}

.valuation-type {
  margin-bottom: 0px;
  margin-top: 0px;
  font-style: italic;
  white-space: nowrap;
  margin-top: 10px;
}

.valuation-value {
  font-weight: bold;
  font-size:x-large;
  white-space: nowrap;
  color:#05BFDD;
  cursor: pointer;
}

.valuation-value:hover {
  text-decoration: underline; /* Apply underline on hover */
}

.tooltip {
  visibility: visible;
  background-color: #05BFDD; /* Vibrant Red */
  color: #000933; /* Clean White */
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 9999;
  bottom: 100%; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip */
  opacity: 1;
  transition: opacity 0.3s;
  font-size: medium;
}

.tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* Adjust if needed based on tooltip position */
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
}

.tooltip-title {
  font-size: larger;
}

.tooltip-after-positive::after {
  border-color: #05BFDD transparent transparent transparent;
}

.tooltip-after-negative::after {
  border-color: #F92654 transparent transparent transparent;
}

.methodology-desc {
  font-size: smaller;
}

.methodology-link {
  text-decoration: underline;
}

.get-3d-button {
  background-color: #8958FE; /* Background color for the button */
  color: #FFFFFF; /* Text color for the button */
  letter-spacing: 0.5px;
  font-weight: bold; /* Make the text bold */
  font-size: 16px; /* Adjust the font size as needed */
  padding: 8px 16px; /* Add padding to the button */
  margin-top: 5px;
  border-top: 3px solid #F92654; 
  border-left: 3px solid #F92654; 
  border-right: 3px solid #05BFDD; 
  border-bottom: 3px solid #05BFDD; 
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */
  max-width: 130px;
  border-radius: 50px;
}

.get-3d-button:hover {
  background-color: #0498B2; /* Change background color on hover */
}

.calculation3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* flex-wrap: wrap; */
}

.item2 {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  flex-grow: 1;
}

.label2 {
  color: #000933; /* Navy for labels */
  margin-bottom: 0px;
  font-style: italic;
  white-space: nowrap;
  margin-top: 10px;
}

.value2 {
  font-weight: bold;
  font-size:x-large;
  white-space: nowrap;
}

@media (max-width: 359px) { /* Adjust breakpoint as necessary */
  .label2, .value2 {
      font-size: 5vw; /* Adjust font size for smaller screens */
  }
}


/* .item.total {
  color: green;
} */

/* .hse-comp {
  margin-top: 5px;
  text-align: left;
} */

.bar:hover {
  fill: darkblue; /* Darker shade when hovered */
  cursor: pointer;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination-container button {
  background-color: #05BFDD;
  color: #000933;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  max-width: 100px;
}

.pagination-container button:hover {
  background-color: #F92654;
}

.pagination-container button:disabled {
  background-color: #CCCCCC;
  cursor: default;
}

.pagination-container button.active {
  background-color: #000933;
  color: #FFFFFF
}

/* Hide page numbers, first/last page, and ellipses on mobile */
@media (max-width: 600px) { /* Adjust the max-width as needed for your design */
  .pagination-container button:not(.prev-button, .next-button),
  .pagination-container span { /* Assuming you use <span> for ellipses */
    display: none;
  }
}

/* Style adjustments for Previous and Next buttons for clarity */
.prev-button, .next-button {
  font-weight: bold;
}

.collegerankings-headline {
  background-color: #ddd;
  padding: 20px 10px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.search-toggle-wrapper {
  display: flex; /* Makes child elements appear side by side */
  align-items: center; /* Centers items vertically */
  justify-content: start; /* Aligns items to the left */
  gap: 10px; /* Adds space between elements */
  padding: 0px 10px; /* Adds padding around the wrapper */
  margin-bottom: 10px;
}

.search-toggle-colleges,
.search-toggle-programs {
  padding: 10px 20px; /* Adds space for a button-like feel */
  font-size: 16px; /* Consistent text size */
  font-weight: normal; /* Default text weight */
  color: #000933; /* Navy text */
  background-color: #FFFFFF; /* White background */
  border: none; /* Remove default borders */
  border-bottom: 3px solid transparent; /* Invisible underline by default */
  cursor: pointer; /* Pointer cursor for interactivity */
  transition: border-color 0.3s ease, font-weight 0.3s ease; /* Smooth transitions */
}

.search-toggle-colleges.active,
.search-toggle-programs.active {
  font-weight: bold; /* Bold text for the active button */
  border-bottom: 3px solid #8958FE; /* Purple underline for the active state */
}

.search-toggle-colleges:hover,
.search-toggle-programs:hover {
  border-bottom: 3px solid #05BFDD; /* Bright blue underline on hover */
}


h2.search-results {
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.search-wrapper {
  padding: 0 10px; /* Adjust padding as needed */
  position: relative;
  width: 100%; /* Make sure this element's parent doesn't restrict its width if you want full screen width */
}

.search-text {
  padding: 0px 10px; /* Adjust for proper text spacing; add right padding to avoid text under the "X" */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
  border: 1px solid #ccc; /* Example border, adjust as needed */
  display: block; /* This makes the input block-level, helping it to take the full width */
}

.clear-search-wrapper {
  position: absolute;
  right: 30px; /* Adjust based on desired distance from the right edge */
  top: 50%;
  transform: translateY(-50%) translateX(45%);
  border: none;
  background: transparent;
}

.clear-search {
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 10; /* Ensure this is above other elements */
  font-weight: lighter;
  font-size: x-large;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 9, 51, 0.75); /* Navy with opacity for overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #FFFFFF; /* Clean white background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px; /* Responsive max-width */
}

.close-button {
  float: right;
  background-color: #FFF; /* Vibrant red */
  border: none;
  color: #000933;
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
  line-height: 20px;
  width: 32px;
  height: 32px;
  text-align: center;
}

.filter-options {
  margin-top: 20px;
}

.filterTitle {
  display: block;
  margin-bottom: 5px;
  color: #000933; /* Navy color for text */
  font-weight: bold;
}

.filterValue, select {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #CCCCCC; /* Light grey border */
}

#stateSelect {
  height: 40px;
}

#programSearch {
  height: 40px;
  font-size:medium;
}

#testScoreTypeSelect {
  height: 50px;
}

.filter-search-results {
  margin-bottom: 6px;
  padding: 2px;
  border-bottom: 2px solid #ddd;
  cursor: pointer;
}

.filter-search-results:hover {
  text-decoration: underline;
}

.filter-searchall {
  margin-bottom: 30px;
  position: relative;
}

/* Style the container of the search results to appear as a dropdown */
.filter-search-results-container {
  position: absolute;
  width: 100%; /* Match the width of the search input field */
  top: 100%; /* Position it right below the search input field */
  left: 0;
  z-index: 1000; /* Ensure it's on top of other content */
  background-color: #eee; /* or any color that fits your design */
  border-right: 2px solid #ddd;
  border-left: 2px solid #ddd;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: add some shadow for better visual separation */
  max-height: 215px; /* Limit the height of the dropdown */
  overflow-y: auto; /* Add scrollbar if content exceeds max height */
  border-radius: 0 0 4px 4px; /* Optional: if you want rounded corners */
}

.filter-search-results {
  /* Style for individual result items */
  padding: 8px;
  cursor: pointer; /* Change cursor to indicate clickable items */
}

.filter-search-results:hover, .filter-search-results:focus {
  background-color: #f0f0f0; /* Highlight effect on hover/focus */
}

.slider {
  /* -webkit-appearance: none; */
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: #05BFDD; /* Bright blue for slider background */
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #000933; /* Purple for slider thumb */
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #000933; /* Purple for slider thumb */
  cursor: pointer;
}

.apply-button {
  background-color: #000933; /* Navy */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
}

.clear-all-button {
  background-color: #F92654;
  color: #000933;
  border: none;
  max-width: 120px;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

.apply-button:hover {
  background-color: #05BFDD; /* Bright blue for hover effect */
}


.btn {
  padding: 10px 20px;
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navy {
  background-color: #000933;
}

.navy:hover {
  background-color: darken(#000933, 10%);
}

.red {
  background-color: #F92654;
}

.red:hover {
  background-color: darken(#F92654, 10%);
}

.blue {
  background-color: #05BFDD;
}

.blue:hover {
  background-color: darken(#05BFDD, 10%);
}

.purple {
  background-color: #8958FE;
}

.purple:hover {
  background-color: darken(#8958FE, 10%);
}

.site-footer {
  background-color: #000933; /* Navy background */
  color: white; /* White text */
  padding: 20px;
  font-size: 14px;
  margin-top: 50px;
}

.footer-content {
  display: flex;
  flex-direction: column; /* Stack sections vertically */
  align-items: flex-start; /* Align content to the left */
}

.footer-section {
  margin-bottom: 10px; /* Spacing between sections */
}

.footer-section h3 {
  color: #05BFDD; /* Vibrant red for headings */
  margin-bottom: 10px;
}

.footer-section p, .footer-section a {
  color: #FFFFFF; /* White text */
}

.footer-section a:hover {
  color: #05BFDD; /* Bright blue for hover */
}

.footer-bottom {
  text-align: center; /* Align bottom text to the left */
  font-size: 12px;
  margin-top: 20px; /* Space above the bottom section */
}

@media screen and (max-width: 600px) {
  .footer-content {
      align-items: flex-start; /* Center content on smaller screens */
  }

  .footer-bottom {
      text-align: center; /* Center bottom text on smaller screens */
  }
}

/* Style for the dropdown container */
.dropdown {
  position: relative; /* Establish a relative positioning context */
}

/* Style for the dropdown button (carrot icon) */
.dropdown button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
}

/* Style for the dropdown menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%; /* Position it below the button */
  right: 0; /* Align it to the right edge of the dropdown button */
  min-width: 225px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Ensure it's above other elements */
}

/* Style for the individual links in the dropdown menu */
.dropdown-menu a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s;
}

/* Hover effect for links */
.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

/* Show the dropdown menu when the parent div has the 'open' class */
.dropdown.open .dropdown-menu {
  display: block;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  max-height: 550px;
  background-color: #000933; /* Adjust background color as needed */
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 30px;
}

.hero-headline {
  font-size: 2.5em; /* Adjust size as needed */
  text-align: left;
  color: #FFFFFF; /* CollegeNPV's navy color for the headline */
  margin-bottom: 20px; /* Space between headline and button */
  max-width: 800px; /* Ensures text is not too wide on larger screens */
}

.hero-programtitle-container {
  width: 100%; /* Full width to allow left alignment */
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  max-width: 800px; /* Ensures text is not too wide on larger screens */
}

.hero-programtitle {
  font-size: 1.1em;
  font-weight: bold;
  text-align: left; /* Ensure text is aligned left */
  color: #FFFFFF; /* CollegeNPV's navy color for the headline */
  margin-top: 20px;
  margin-bottom: 5px; /* Space between headline and button */
}

.hero-programtitle-link {
  font-size: 0.7em;
  text-decoration: underline;
  color: #05BFDD;
}

.hero-headlinehighlight {
  color: #05BFDD;
}

.browse-button {
  font-size: 1.2em;
  font-weight: 600;
  padding: 10px 20px;
  margin-bottom: 20px;
  width: 90vw;
  min-width: 250px;
  max-width: 800px;
  border-radius: 20px;
  border: none;
  background-color: #F92654; /* Vibrant red for the button */
  color: #000933; /* White text */
  cursor: pointer;
  transition: background-color 0.3s ease;

  /* Hover effect */
  &:hover {
    background-color: #C71C4D; /* Darker shade of red */
  }
}

.hero-program {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden; 
  white-space: nowrap;
  padding: 10px 0;
  max-width: 100%;
  gap: 10px; /* Space between items */
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* Media query for screens 800px or wider */
@media (min-width: 800px) {
  .hero-program {
    max-width: 800px;
  }
}

.hero-program::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.hero-program-item {
  display: inline-block; 
  padding: 10px 20px;
  border-radius: 5px;
  white-space: nowrap; /* Prevent text from wrapping */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 0px;
  height: 100px;
  cursor: pointer;
}

.hero-program-item:hover {
  background-color: #F92654; /* Hover color */
  color: #FFFFFF; /* Text color on hover */
}

.hero-program-item-title {
  color: #FFFFFF;
  font-weight: bold;
}

.hero-program-item-emoji {
  font-size: 2.5em;
}

.hero-section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  max-height: 500px;
  background-color: #F92654; /* Adjust background color as needed */
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.hero-sectionp {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  margin-top: 0px;
}

.hero-headline2 {
  font-size: 2.5em; /* Adjust size as needed */
  text-align: left;
  color: #000933; /* CollegeNPV's navy color for the headline */
  margin-bottom: 20px; /* Space between headline and button */
  max-width: 800px; /* Ensures text is not too wide on larger screens */
}

.benefits-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  text-align: left;
  background-color: #05BFDD; /* Light background for contrast */
  border-radius: 10px;
  margin-bottom: 30px;
}

.benefits-headline {
  font-size: 2em;
  text-align: left;
  color: #000933; /* Consistent with your branding */
  margin-bottom: 20px; /* Space between headline and benefits list */
  margin-top: 0px;
}

.benefits-list {
  list-style: none; /* Removes default list styling */
  padding: 0;
  margin-bottom: 30px; /* Space between list and button */
}

.benefits-list li {
  font-size: 1.4em;
  margin-bottom: 10px; /* Space between list items */
  font-weight: 500;
}

.explore-button {
  font-size: 1.2em;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 20px;
  width: 90vw;
  min-width: 250px;
  max-width: 800px;
  border: none;
  background-color: #000933; /* Bright blue for the button */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0498B2; /* A slightly darker shade for hover */
  }
}

.container-csp {
  max-width: 800px;
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-around;
  grid-gap: 10px;
}

.csp-link {
  font-weight: bold;
  text-decoration: underline;
  text-align: left;
}

.container-csp .card .card-top {
  display: flex;
  align-items: center; /* This will vertically center the items in the flex container */
  justify-content: space-between; /* This will place maximum space between the two items, you can adjust as needed */
  margin-bottom: 5px;
}

.container-csp .card .card-top .card-ranking {
  margin-right: 10px; /* Adjust the margin as needed for spacing between the text and button */
}

.cp-valuation {
  display: flex; /* This enables flexbox for the container */
  flex-direction: column; /* This stacks its children vertically */
  align-items: left;
  margin-top: auto;
  position: relative;
  font-weight: bold;
  background-color: #000933;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  margin-top: 20px;
  max-width: 850px;
}

.cp-valuation-section {
  text-align: left; /* This centers the text inside the valuation-section */
  padding: 10px;
}

.cp-valuationimg {
  max-width: 100%; /* Ensures the image is responsive and fits its parent */
  height: auto; /* Keeps the image aspect ratio */
}

.cp-valuationtitle {
  color: #000933;
}

.cp-valuationdesc {
  color: #FFFFFF;
}

.cp-valuationimg {
  max-width: 200px;
  height: auto;
  border-radius: 10px;
}

.cp-def-valuation {
  margin-left: 0px;
  color: #eee;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex-grow: 1;
  padding: 10px;
}

.cp-def-valuation-type {
  margin-bottom: 0px;
  margin-top: 0px;
  font-style: italic;
  /* white-space: nowrap; */
}

.cp-def-valuation-value {
  font-weight: bold;
  font-size:x-large;
  /* white-space: nowrap; */
  color:#05BFDD;
}

.cp-def-factors {
  color: #FFFFFF;
  margin-top: 10px;
}

.cp-def-factorlist {
  font-size: large;
}

.cp-brandmark {
  position:absolute;
  top: 20px;
  right: 15px;
  /* Adjust width and height as needed */
  width: 45px; /* Example width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
}

.cp-outcome-metrics {
  background-color: #DDD;
  border-radius: 5px;
  padding: 10px;
  max-width: 850px;
}

.cp-outcome-metric-value {
  font-size: x-large;
  font-weight: bold;
}

.cp-costs {
  background-color: #000933;
  border-radius: 5px;
  padding: 10px;
}

.cp-cost-label {
  color: #FFFFFF;
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cp-cost-item {
  color:#F92654;
}

.cp-grant-item {
  color:#05BFDD;
}

.cp-cost-horizontal-divider {
  height: 1px; /* Thickness of the divider */
  background-color: #D3D3D3; /* Light grey color */
  margin: 5px 0; /* Adds space above and below the divider */
}

.cp-example-h4 {
  margin-bottom: 5px;
}

.desc-negoutcome {
  color:#F92654;
}

.desc-posoutcome {
  color:#05BFDD;
}

.methodology-img {
  border-radius: 5px;
  max-height: 500px;
}

.filter-sort-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  position: sticky;
  top: 60px; /* Adjust this value as needed */
  background-color: #fff; /* Background color to ensure content below doesn't show through */
  z-index: 500;
  padding: 0px 0px 5px 0px;
}

.filter-button {
  font-weight: 500;
  text-align: center;
  font-size: medium;
  padding: 8px;
  /* padding: 10px 20px; */
  border-radius: 5px;
  width: 90vw;
  max-width: 170px;
  height: 40px;
  margin-left: 20px;
  background-color: #f5f5f5; /* Bright blue for the button */
  color: #000933;
  border: 2px solid #DDD;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #000933; /* A slightly darker shade for hover */
    color: #FFF;
    border: 2px solid #05BFDD;
  }
}

.filter-active-indicator {
  display: inline-block;
  margin-left: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #05BFDD; /* Red color for visibility, adjust as needed */
  vertical-align: middle;
}


.sort-dropdown {
  padding: 8px;
  border-radius: 5px;
  border: 2px solid #DDD;
  width: 90vw;
  max-width: 170px;
  height: 40px;
  font-weight: 500;
  font-size: medium;
  text-align: left;
  margin:0px;
  margin-right: 20px;
  background-color: #f5f5f5;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    border: 2px solid #05BFDD;
  }
}

.back-button {
  background-color: #f5f5f5;
  color: #000933; 
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  border: 2px solid #000933;
  cursor: pointer;
  font-weight: bold;
  max-width: 100px;
  margin-left: 10px;

  &:hover {
    background-color: #000933; /* A slightly darker shade for hover */
    color: #FFF;
    border: 2px solid #05BFDD;
  }
}

.horizontal-divider {
  height: 2px; /* Thickness of the divider */
  background-color: #D3D3D3; /* Light grey color */
  margin: 20px 0; /* Adds space above and below the divider */
}

.program-title {
  margin-bottom: 5px;
}

.program-major {
  padding: 8px;
  background-color: #DDDDDD;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.program-major-value {
  margin-left: 5px;
}

.register-button {
  font-size: 1.2em;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #000933; /* Bright blue for the button */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0498B2; /* A slightly darker shade for hover */
    color:#000933;
  }
}

.login-button {
  font-size: 1.2em;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #000933; /* Bright blue for the button */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0498B2; /* A slightly darker shade for hover */
    color:#000933;
  }
}

.user-type {
  margin-bottom: 5px;
  padding: 5px 7px;
  height: 50px;
  font-size: larger;
  border: 2px solid #ddd;
  border-radius: 10px;
  color: #333;
}

input.user-email::placeholder, input.user-password::placeholder {
  color: #333;
}

.register-benefits {
  background-color: #05BFDD;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 10px;
}

.register-benefits-headline {
  font-size: 1.5em;
  font-weight: 500;
  color: #000933; /* Consistent with your branding */
  margin-bottom: 5px; /* Space between headline and benefits list */
  margin-top: 0px;
}

.register-benefits-list {
  list-style: none; /* Removes default list styling */
  padding: 0;
  margin-bottom: 30px; /* Space between list and button */
}

.register-benefits-list li {
  font-size: 1.1em;
  margin-bottom: 10px; /* Space between list items */
  font-weight: 500;
}

.register-disclosure {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: .9rem;
}

.register-disclosure a{
  text-decoration: underline;
}

.register-login-reroute {
  margin-top: 16px;
  text-align: center;
}

.register-login-reroute-link {
  text-decoration: underline;
}

.fos-container {
  max-width: 100%;
  margin: 10px auto;
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-around;
  grid-gap: 10px;
  padding: 0px 10px;
}

.fos-card {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 450px;
  min-height: 400px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.fos-name {
  margin-top: 0px;
  margin-bottom: 0px;
}

.fos-card-name {
  margin-top: 0px;
  margin-bottom: 5px;
}

.fos-major {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: large;
}

.fos-rank {
  margin-bottom: 5px;
  font-weight: bold;
}

.fos-valuation {
    margin-top: auto;
    margin-bottom: 10px;
    position: relative;
    font-weight: bold;
    background-color: #000933;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}

.fos-valuation-section {
  margin-left: 16px;
  color: #eee;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex-grow: 1;
}

.fos-valuation-type {
  margin-bottom: 0px;
  margin-top: 0px;
  font-style: italic;
  white-space: nowrap;
  margin-top: 10px;
}

.fos-valuation-value {
  font-weight: bold;
  font-size:x-large;
  white-space: nowrap;
  color:#05BFDD;
  cursor: pointer;
  margin-bottom: 5px;
}

.fos-valuation-value-debt {
  font-weight: bold;
  font-size:x-large;
  text-decoration: underline;
  white-space: nowrap;
  color:#F92654;
  cursor: pointer;
  margin-bottom: 10px;
}

.fos-valuation-debt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex-grow: 1;
  margin-bottom: 5px;
}

.fos-valuation-debt-button {
  font-size: large;
  font-weight: bold;
  padding: 5px 20px;
  max-width: 150px;
  border-radius: 20px;
  border: none;
  background-color: #F92654; /* Vibrant red for the button */
  color: #000933; /* White text */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 5px;
  /* Hover effect */
  &:hover {
    background-color: #C71C4D; /* Darker shade of red */
  }
}

.fos-rank-title {
  margin-top: 10px;
  margin-bottom: 5px;
}

.fos-valuepct {
  font-weight: 500;
}

.fos-brandmark {
  position:absolute;
  bottom: 16px;
  right: 10px;
  /* Adjust width and height as needed */
  width: 45px; /* Example width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
}

.fos-rank-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* flex-wrap: wrap; */
}

.fos-item2 {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  flex-grow: 1;
}

.fos-label2 {
  color: #000933; /* Navy for labels */
  margin-bottom: 0px;
  font-style: italic;
  white-space: nowrap;
  margin-top: 5px;
}

.fos-value2 {
  font-weight: bold;
  font-size:x-large;
  white-space: nowrap;
}

@media (max-width: 359px) { /* Adjust breakpoint as necessary */
  .label2, .value2 {
      font-size: 5vw; /* Adjust font size for smaller screens */
  }
}

.fos-card-top {
  display: flex;
  align-items: center; /* This will vertically center the items in the flex container */
  justify-content: space-between; /* This will place maximum space between the two items, you can adjust as needed */
  padding: 0px;
  border-radius: 5px;
}

.fos-card-top .fos-card-ranking {
  margin-right: 10px; /* Adjust the margin as needed for spacing between the text and button */
}

.fos-card-ranking {
  font-weight: bold;
  padding: 0px;
}

.fos-horizontal-divider {
  height: 1px; /* Thickness of the divider */
  background-color: #D3D3D3; /* Light grey color */
  margin-top: 5px;
  margin-bottom: 10px;
}

.fos-card-info {
  display: flex; /* This enables Flexbox */
  flex-wrap: nowrap; /* This prevents flex items from wrapping */
  justify-content: space-between; /* This spaces out the flex items */
  align-items: flex-start; /* This aligns items at the start of the cross axis */
  width: 100%; /* Ensure the container takes full width */
  margin-bottom: 10px;
  min-height: 150px;
}

.fos-card-info-left {
  flex: 3; /* Takes up more space */
  margin-right: 5px;
}

.fos-card-info-right {
  flex: 2; /* Takes up less space */
}

/* Additional styles for responsiveness */
@media (max-width: 300px) {
  .fos-card-info {
      flex-direction: column; /* Stack the children vertically on smaller screens */
  }
}

.fos-card-img {
  border-radius: 4px;
}

.fos-location {
  text-align: left;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.fos-search-results {
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.debt-modal-content {
  background-color: #FFFFFF; /* Clean white background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px; /* Responsive max-width */
  height: 80%;
  overflow-y: auto;
}

.h2-debt-modal {
  margin-top: 0px;
}

.debt-modal-subheader {
  margin-bottom: 5px;
}

.debt-modal-step {
  background-color:#DDD;
  padding: 8px;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Aligns children (the button) to the right */
}


.debt-modal-next {
  font-size: large;
  font-weight: bold;
  padding: 10px 15px;
  max-width: 150px;
  border-radius: 20px;
  border: none;
  background-color: #000933;
  color: #FFFFFF; /* White text */
  cursor: pointer;
  margin-top: 20px;
}

.debt-modal-prev {
  font-size: large;
  font-weight: bold;
  padding: 10px 15px;
  max-width: 90px;
  border-radius: 20px;
  border: none;
  background-color: #FFFFFF;
  color: #000933; /* White text */
  cursor: pointer;
  margin-top: 20px;
  border: 2px solid #000933;
}

.debt-modal-value-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
  font-weight: bold;
}

.debt-modal-tuition-question {
  display: flex;
  align-items: center; /* Aligns the text and the dropdown vertically */
  margin-bottom: 10px; /* Adds some space below the question for clarity */
}

.debt-modal-tuition-dropdown {
  margin-left: 10px; /* Space between the question text and the dropdown */
  margin-bottom: 0px;
  padding: 5px 10px; /* Padding inside the dropdown for better text visibility */
  border-radius: 5px; /* Optional: Rounds the corners of the dropdown */
  border: 1px solid #000933; /* Optional: Adds a light border to the dropdown */
  background-color: white; /* Ensures the dropdown is clearly visible */
  max-width: 120px;
}

.debt-modal-value-container-cost-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
  font-weight: bold;
  background-color:#000933;
  border-radius: 5px;
  padding: 8px;
}

.debt-modal-cost-title {
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 215px;
}

.debt-modal-cost-value {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight:bolder;
  color:#F92654;
}

.debt-modal-contribution-value {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight:bolder;
  color:#05BFDD;
}

.debt-modal-field-title {
  margin-bottom: 20px;
}

.debt-modal-field {
  margin-top: 10px;
}


.debt-modal-value-container-review {
  width: 100%; 
  font-weight: bold;
  background-color:#000933;
  border-radius: 5px;
  padding: 8px;
}

.debt-modal-tooltip {
  font-size: smaller;
  font-style: italic;
  margin-bottom: 10px;
}

.horizontal-divider-debt-modal {
  height: 2px; /* Thickness of the divider */
  background-color: #D3D3D3; /* Light grey color */
  margin: 10px 0; /* Adds space above and below the divider */
}

.debt-modal-loan-container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  position: relative;
}

.debt-modal-loan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.debt-modal-loan-header h3{
  margin-top: 0px;
  margin-bottom: 5px;
}

.expand-button {
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
  max-width: 30px;
  padding: 0px;
}


.dml-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color:#000933;
  border-radius: 5px;
  padding: 8px;
  /* flex-wrap: wrap; */
}

.dml-item2 {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  flex-grow: 1;
}

.dml-label2 {
  color: #FFFFFF; /* Navy for labels */
  margin-bottom: 0px;
  font-style: italic;
  font-weight: bold;
  white-space: nowrap;
  margin-top: 5px;
}

.dml-value2 {
  font-weight: bold;
  font-size:x-large;
  white-space: nowrap;
  color:#F92654;
}

.debt-modal-loan-details {
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #ccc;
  padding: 8px;
  border-radius: 8px;;
}

.clb-section {
  margin-bottom: 15px;
  border: 2px solid #05BFDD;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.clb-section-container {
  background-color: #f4f4f4;
}

.clb-tooltip {
  background-color: #f4f4f4;
  color: #000933;
  padding: 8px 8px 8px 8px;
  font-size: 0.95em;
  font-style: italic;
}

.clb-section-header {
  background-color: #05BFDD;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000933;
  font-size: 1.2em;
  font-weight: bold;
}

.clb-section-header h2 {
  margin: 0;
}

.clb-description {
  margin-top: 0px;
}

.clb-toggle-icon {
  font-size: 1.5em;
  font-weight: bold;
  color: #000933;
  transition: transform 0.3s ease-in-out;
}

.clb-section-content {
  background-color: #F4F4F4;
  padding: 0px 5px 10px 5px;
  animation: fadeIn 0.3s ease-in-out;
}

.clb-school-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.clb-school-item {
  padding: 10px 10px;
  font-size: 1em;
  color: #000933;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  background-color: #fff;
}

.clb-school-item-section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clb-school-item-header {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: space-between; /* Ensure space between the name and the button */
}

.clb-school-name {
  flex: 1;
  margin-right: 10px;
  word-wrap: break-word;
}

.clb-school-name-h3 {
  margin-top: 0px;
  margin-bottom: 5px;
}

.clb-school-name-h3:hover {
  text-decoration: underline; /* Underline text on hover */
}

.clb-budget-button {
  background-color: #6e46c1; /* Bright Blue */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px; /* Add some space above the button */
  transition: background-color 0.3s ease;
}

.clb-budget-button:hover {
  opacity: 0.9;
}

.clb-remove-button-container {
  display: flex;
  align-items: center;
}

.clb-remove-button {
  background-color: #FFF;
  color: #000933;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
  line-height: 20px;
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
}

.clb-fos-valuation {
  margin-top: auto;
  margin-bottom: 0px;
  position: relative;
  font-weight: bold;
  background-color: #000933;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

.clb-fos-valuation-section {
margin-left: 10px;
color: #eee;
display: flex;
flex-direction: column;
align-items: flex-start;
text-align: left;
flex-grow: 1;
}

.clb-fos-valuation-type {
margin-bottom: 0px;
margin-top: 0px;
font-style: italic;
white-space: nowrap;
margin-top: 10px;
}

.clb-fos-valuation-value {
font-weight: bold;
font-size:x-large;
white-space: nowrap;
color:#05BFDD;
cursor: pointer;
margin-bottom: 5px;
}

.clb-header {
  display: flex;
  align-items: center; /* Aligns items vertically */
}

.clb-header h3 {
  margin-right: 10px;
  color: #000933;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.clb-header-brandmark {
  width: 37px; /* Example width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px;
  filter: grayscale(100%);
}

.clb-fos-brandmark {
  position:absolute;
  bottom: 14px;
  right: 10px;
  /* Adjust width and height as needed */
  width: 45px; /* Example width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
}

.clb-school-item-section2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* flex-wrap: wrap; */
}

.clb-button-section {
  display: flex;
  flex-direction: column; /* Stack the children vertically */
  align-items: center; /* Center children horizontally */
}

.clb-add-button {
  font-size: large;
  font-weight: bold;
  padding: 10px 15px;
  max-width: 300px;
  border-radius: 20px;
  border: 2px solid #000933;
  background-color: #ddd;
  color: #000933; /* White text */
  cursor: pointer;
  margin-top: 10px;
}

.clb-modal-remove {
  font-size: large;
  font-weight: bold;
  padding: 10px 15px;
  max-width: 150px;
  border-radius: 20px;
  border: none;
  background-color: #F92654;
  color: #000933; /* White text */
  cursor: pointer;
  margin-top: 20px;
}

.clb-modal-cancel {
  font-size: large;
  font-weight: bold;
  padding: 10px 15px;
  max-width: 90px;
  border-radius: 20px;
  border: none;
  background-color: #FFFFFF;
  color: #000933; /* White text */
  cursor: pointer;
  margin-top: 20px;
  border: 2px solid #000933;
}

/* Simple fade-in animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.college-outcomes-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1rem;
  font-family: Arial, sans-serif;
  margin-bottom: 8px;
}

.college-outcomes-table thead tr {
  background-color: #000933; /* Navy Blue for header */
  color: #ffffff; /* White text color */
  text-align: left;
}

.college-outcomes-table thead th:first-child {
  border-top-left-radius: 8px;
  text-align: center;
}

.college-outcomes-table thead th:last-child {
  border-top-right-radius: 8px;
  text-align: center;
}

.college-outcomes-table th, .college-outcomes-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
}

.college-outcomes-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3; /* Light grey for even rows */
}

.college-outcomes-table tbody tr:nth-of-type(odd) {
  background-color: #ffffff; /* White for odd rows */
}

.college-outcomes-row:hover {
  background-color: #ececec; /* Light hover effect */
}

.college-outcomes-major {
  font-weight: bold;
}

.college-outcomes-roi {
  text-align: right;
  font-weight: bold;
}

.college-outcomes-link {
  text-decoration: underline;
}

.cp-card {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 400px;
  width: 100%; /* 10px for the margin between cards */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 0px;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.cp-card-top {
  display: flex;
  align-items: center; /* This will vertically center the items in the flex container */
  justify-content: space-between; /* This will place maximum space between the two items, you can adjust as needed */
  margin-bottom: 5px;
}

.cp-card-ranking {
  margin-right: 10px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
}

.cp-card-plus {
  width: 90px; /* Size of the button */
  height: 25px; /* Size of the button */
  border-radius: 20px; /* Makes the button round */
  font-size: 15px; /* Size of the plus sign */
  font-weight: bold;
  text-align: center; /* Centers the plus sign horizontally */
  padding: 0; /* Removes any default padding */
  border: 1px solid #000933; /* Optional: adds a border to the button */
  color: #000933; /* Color of the plus sign */
  background-color: #05BFDD;
  cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.cp-card-plus::before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.cp-card-img {
  max-width: 100%;
  height: auto;
  min-height: 193px;
  border-radius: 4px;
  margin-bottom: 0px;
}

.cp-card-college {
  text-decoration: none;
  color: inherit;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.heatmap-cip-link {
  font-size: 1.2em;
  text-decoration: underline;
  color: #000933;
}

.footer-link {
  text-decoration: underline;
}

.major-risklevel {
  font-size: 24px;
  background-color: #DDD;
  padding: 16px 12px;
  border-radius: 8px;
  font-weight: bold;
}

.major-risklevel-desc {
  font-size: 18px;
  margin-top:10px;
  font-style: italic;
  font-weight: normal;
}

.major-worthit {
  background-color: #000933;
  color: white;
  padding: 16px 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
}

.major-worthit-disclaimer {
  font-size: 0.8rem;
  margin-top: 15px;
  font-style: italic;
}

.major-methodology-link {
  text-decoration: underline;
}

.major-highlight {
  color:#05BFDD;
}

.major-button {
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  width: 90vw;
  min-width: 250px;
  max-width: 800px;
  border: none;
  background-color: #05BFDD; /* Bright blue for the button */
  color: #000933;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 auto;

  &:hover {
    background-color: #0498B2;
    color: #000933;
  }
}

.major-brandmark {
  position:absolute;
  bottom: 10px;
  right: 10px;
  /* Adjust width and height as needed */
  width: 45px; /* Example width */
  height: auto;
  object-fit: contain;
}

.table-container {
  max-height: min(800px, 70vh); /* Lesser of 800px or 80% of the viewport height */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.dashboard-table {
  width: 100%;
  border-collapse: collapse;
}

.dashboard-table thead {
  position: sticky;
  top: 0; /* Sticks to the top of the container or viewport */
  z-index: 1; /* Ensures the header is above the table body */
  background-color: #000933; /* Matches header background color */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
}

.dashboard-table thead th:first-child {
  border-top-left-radius: 8px;
}

.dashboard-table thead th:last-child {
  border-top-right-radius: 8px;
}

.dashboard-table-header {
  font-weight: bold;
  font-size: 0.8rem;
  background-color: #000933;
  color: white;
}

.dashboard-table-header-item {
  padding: 10px 10px;
}

.dashboard-table-code {
  font-weight: bold;
}

.dashboard-table th,
.dashboard-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  text-align: center;
}

/* Horizontal scrolling improvements */
.table-container::-webkit-scrollbar {
  height: 8px; /* Custom scrollbar height for horizontal scrolling */
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* Light grey for scrollbar thumb */
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #999999; /* Darker grey on hover */
}

.dashboard-table td:first-child, /* First column (Field) */
.dashboard-table th:first-child {
  text-align: left; /* Left align for Field column */
}

.dashboard-table tbody tr.code-row:nth-of-type(even) {
  background-color: #f3f3f3; /* Light grey for even CODE rows */
}

.dashboard-table tbody tr.code-row:nth-of-type(odd) {
  background-color: #ffffff; /* White for odd CODE rows */
}

.dashboard-table tbody tr.cipcode-row:nth-of-type(even) {
  background-color: #f3f3f3; /* Slightly lighter grey for even CIPCODE rows */
}

.dashboard-table tbody tr.cipcode-row:nth-of-type(odd) {
  background-color: #ffffff; /* White for odd CIPCODE rows */
}

.dashboard-table td {
  font-size: 0.8rem;
}

.dashboard-table-cipdesc-links {
  font-size: 0.7rem;
  margin-top: 5px;
}

.dashboard-table-cipdesc-link {
  text-decoration: underline;
  font-weight: normal;
  color: #007bff;
}

.dashboard-table-cipdesc-link:hover {
  color: #0056b3; /* Darker blue for hover effect */
}

.dashboard-table-riskdesc {
  font-size: 0.6rem;
  font-style: italic;
  margin-top: 2px;
}

.dashboard-table-roi {
  padding: 8px 8px;
  border-radius: 5px;
}

.dashboard-table-expand {
  font-size: 0.6rem;
}

/* Main container for centering the content */
.collegeadvisor-container {
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.collegeadvisor-headercontainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.collegeadvisor-brandmark {
  width: 50px;
  height: auto;
}

.collegeadvisor-welcome {
  flex-direction: column;
}

.collegeadvisor-free-container {
  flex-direction: column;
}

/* Card styling */
.collegeadvisor-card {
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 700px;
}

/* Header styling */
.collegeadvisor-header {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Description text */
.collegeadvisor-description {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Ordered list for the steps */
.collegeadvisor-steps-list {
  text-align: left;
  margin-bottom: 20px;
  padding-left: 10px;
  list-style-type: none;
}

/* Individual steps */
.collegeadvisor-step {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Button styling */
.collegeadvisor-button {
  background-color: #6e46c1; /* Bright Blue */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px; /* Add some space above the button */
  transition: background-color 0.3s ease;
}

.collegeadvisor-button:hover {
  opacity: 0.9;
}

/* Add hover effect to the button */
.collegeadvisor-button:hover {
  background-color: #6e46c1;
}

/* Modal container */
.collegeadvisor-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.collegeadvisor-modal-content {
  position: relative; /* Ensures close button is positioned relative to this box */
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
  overflow-y: auto;
  max-height: 80%;
}

/* Modal header */
.collegeadvisor-modal-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Modal description */
.collegeadvisor-modal-description {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Input fields */
.collegeadvisor-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.collegeadvisor-input:focus {
  outline: none;
  border-color: #05BFDD;
  box-shadow: 0 0 5px rgba(5, 191, 221, 0.5);
}


/* Dropdown container */
.collegeadvisor-dropdown {
  text-align: left;
}

/* Dropdown select */
.collegeadvisor-select {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  appearance: none; /* Removes default styles for better control */
  cursor: pointer;
}

/* Focus effect for dropdown */
.collegeadvisor-select:focus {
  outline: none;
  border-color: #05BFDD;
  box-shadow: 0 0 5px rgba(5, 191, 221, 0.5);
}

/* Buttons container */
.collegeadvisor-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* User type buttons */
.collegeadvisor-user-button {
  background-color: #05BFDD;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  flex: 1;
  margin: 0 5px;
}

.collegeadvisor-user-button:hover {
  background-color: #0299b3;
}

/* Next button */
.collegeadvisor-next-button {
  background-color: #6e46c1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

/* Close button */
.collegeadvisor-close-button {
  position: absolute; /* Position relative to modal content */
  top: 10px;
  left: 10px; /* Top-left corner of modal content */
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  outline: none;
  line-height: 1;
  padding: 0; /* Remove any default padding */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px; /* Ensure consistent button size */
  height: 24px;
}

/* Error messages */
.collegeadvisor-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Disclosure text */
.collegeadvisor-disclosure {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
}
.collegeadvisor-disclosure a {
  color: #000933;
  text-decoration: underline;
}

.collegeadvisor-disclosure a:hover {
  text-decoration: underline;
}

/* Toggle text for switching between register and login */
.collegeadvisor-toggle-text {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.collegeadvisor-link {
  color: #000933;
  cursor: pointer;
  text-decoration: underline;
}

.collegeadvisor-modal-price {
  text-align: left;
  margin-bottom: 0px;
}

.collegeadvisor-modal-pricedesc {
  text-align: left;
  font-weight: lighter;
  margin-top: 0px;
}

.collegeadvisor-modal-desc {
  text-align: left;
}

/* Upgrade Account Layout */
.collegeadvisor-upgrade-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.collegeadvisor-upgrade-option {
  background-color: #f4f4f4; /* Lime green background */
  padding: 15px;
  border-radius: 10px;
  text-align: left;
}

.collegeadvisor-upgrade-option h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

/* Upgrade Action Buttons */
.collegeadvisor-upgrade-actions {
  display: flex;
  justify-content: space-around;
}

.collegeadvisor-upgrade-features {
  list-style: none;
  padding: 0px;
  font-size: 14px;
}

.collegeadvisor-upgrade-features li {
  margin-bottom: 5px;
}

.collegeadvisor-sections {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.collegeadvisor-section {
  flex: 1 1 calc(50% - 20px); /* Two sections side by side */
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.collegeadvisor-section-complete {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Add space between the heading and button */
  gap: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.collegeadvisor-section-header {
  font-size: 1.25rem;
  margin-bottom: 10px;
  margin-top: 10px;
}

.collegeadvisor-section-header-complete {
  font-size: 1.25rem;
  margin-bottom: 0px;
  margin-top: 0px;
  flex-grow: 1;
}

.collegeadvisor-section-description {
  font-size: 0.95rem;
  margin-bottom: 15px;
}

.collegeadvisor-section-content {
  font-size: 0.9rem;
}

.financialmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Prevent background scrolling */
}

.financialmodal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh; /* Ensure the modal doesn't extend beyond the viewport height */
  overflow-y: auto; /* Add vertical scrolling if content exceeds the modal's height */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.financialmodal-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000933; /* Use brand navy color */
}

.financialmodal-description {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #333; /* Neutral color for text */
}

.financialmodal-form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between form groups */
}

.financialmodal-form-group {
  display: flex;
  flex-direction: column;
}

.financialmodal-label {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #000933; /* Use brand navy color */
}

.financialmodal-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}

.financialmodal-select {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 0px;
}

.financialmodal-select:focus {
  outline: 2px solid #6e46c1;
}

.financialmodal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}

.financialmodal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  background: #6e46c1; /* Purple brand color */
  color: #fff;
}

.financialmodal-button.financialmodal-cancel {
  background: #ccc;
  color: #000933; /* Use brand navy color for cancel button text */
  max-width: 125px;
}

.financialmodal-button:hover {
  opacity: 0.9;
}

.financialmodal-button:disabled {
  background: #ddd;
  color: #5a5e72;
  cursor: not-allowed;
}

/* Scrollbar for modal content */
.financialmodal-content::-webkit-scrollbar {
  width: 6px;
}

.financialmodal-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.financialmodal-content::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

.financialmodal-fafsadescription {
  margin-top: 5px;
  margin-bottom: 5px;
}

.financialmodal-link {
  color: #0073e6; /* Bright blue link color */
  text-decoration: underline;
  font-weight: bold;
}

.financialmodal-link:hover {
  color: #005bb5; /* Darker blue on hover */
  text-decoration: none;
}

.collegebudgeting-h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  position: sticky;
  top: 60px; /* Distance from the top of the viewport */
  z-index: 1000;
  background-color: #fff;
  padding: 0px 10px 10px 10px;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.1);
}

.collegebudgeting-cost-container {
  background: #ffffff; /* Subtle light gray background */
  padding: 20px;
  border-radius: 12px; /* Rounded corners for modern look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  max-width: 800px; /* Limit the width for readability */
  margin: 0 auto; /* Center the container */
  margin-bottom: 20px;
}

.collegebudgeting-section-header {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 5px;
  text-align: left; /* Center the section header */
}

.collegebudgeting-cost-category {
  background: #ffffff; /* White background for each category */
  border: 1px solid #e0e0e0; /* Light gray border for separation */
  padding: 15px;
  border-radius: 8px; /* Add a bit of rounding */
  margin-bottom: 15px; /* Space between categories */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Light shadow for subtle depth */
}

.collegebudgeting-cost-category-total {
  background: #ffffff; /* White background for each category */
  border: 1px solid #e0e0e0; /* Light gray border for separation */
  padding: 15px;
  border-radius: 8px; /* Add a bit of rounding */
  margin-bottom: 0px; /* Space between categories */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Light shadow for subtle depth */
}

.collegebudgeting-cost-subcategory {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collegebudgeting-title-container {
  display: flex;
  flex-direction: column;
}

.collegebudgeting-cost-itemtitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000933; /* Use brand navy color for consistency */
}

.collegebudgeting-cost-itemvalue {
  font-size: 1.4rem;
  font-weight: 700;
  color: #F92654; /* Vibrant red for emphasis */
  margin-left: 5px;
}

.collegebudgeting-cost-itemtitle-total {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000933; /* Brand navy for totals */
}

.collegebudgeting-cost-itemvalue-total {
  font-size: 1.6rem;
  font-weight: 800;
  color: #F92654; /* Bright red for totals */
  margin-left: 5px;
}

.collegebudgeting-label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
  margin-right: 20px;
}

.collegebudgeting-dropdown {
  font-size: 16px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ccc; /* Light gray border for dropdown */
  margin: 10px 0;
  background: #ffffff; /* White background for dropdown */
  width: 100%; /* Make dropdown fill the container */
  max-width: 300px; /* Add a max width for readability */
}

.collegebudgeting-cost-itemvalue-scholarship {
  font-size: 1.3rem;
  font-weight: 700;
  color: #05BFDD; /* Bright blue for scholarships */
  margin-left: 5px;
}

.collegebudgeting-cost-hint {
  margin-top: 10px;
  margin-bottom: 0px;
  font-style: italic;
}

.collegebudgeting-scholarship-input {
  width: 100px;
  padding: 5px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: right;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f4f4f4;
  color: #05BFDD; /* Matches scholarship color */
}

.collegebudgeting-scholarship-input:focus {
  outline: none;
  border-color: #05BFDD; /* Bright blue border on focus */
  background-color: #ffffff; /* White background on focus */
}


@media (max-width: 768px) {
  .collegebudgeting-cost-container {
    padding: 15px;
  }

  .collegebudgeting-cost-category {
    padding: 10px;
  }

  .collegebudgeting-cost-itemvalue,
  .collegebudgeting-cost-itemvalue-total,
  .collegebudgeting-cost-itemvalue-scholarship {
    font-size: 1.2rem;
  }

  .collegebudgeting-cost-itemtitle,
  .collegebudgeting-cost-itemtitle-total {
    font-size: 1.1rem;
  }

  .collegebudgeting-dropdown {
    font-size: 0.9rem;
  }
}

.collegebudgeting-loans-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.collegebudgeting-loans-table th,
.collegebudgeting-loans-table td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.collegebudgeting-loans-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: #000933; /* Navy color for header */
}

.collegebudgeting-loans-table td {
  font-size: 1rem;
  font-weight: 500;
}

.collegebudgeting-cost-subheader {
  margin-top: 10px;
  margin-bottom: 5px;
}

.fields-of-study-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.fields-of-study-table th,
.fields-of-study-table td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

.fields-of-study-table th {
  background-color: #000933; /* Navy brand color */
  color: white;
}

.fields-of-study-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.fields-of-study-table tr:hover {
  background-color: #f1f1f1;
}

.fields-of-study-table td {
  color: #000933; /* Navy brand color */
}

.fields-of-study-table td:nth-child(2),
.fields-of-study-table td:nth-child(3) {
  text-align: right;
}

.collegebudgeting-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-button {
  font-size: 1.8rem;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  width: 50px;
  color: #000933;
  padding: 0px;
}

.testimonials-vertical {
  display: flex;
  flex-direction: column; /* Stack testimonials vertically */
  gap: 20px; /* Space between testimonials */
}

.testimonial {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
}

.testimonial p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.testimonial-name {
  font-size: 1.1rem;
}

.testimonial-desc {
  font-size: 0.9rem;
  font-style: italic;
}

.collegeadvisor-product-wrapper {
    display: flex;
    flex-direction: column; /* Keep elements stacked */
    justify-content: center; /* Center vertically */
    align-items: center;
}

.tab-container {
  margin-top: 20px;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 10px;
  border: none;
  background-color: #f9f9f9;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #ccc; /* Bright blue for active tab */
  font-weight: bold;
}

.tab-content-container {
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-content h3 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #000933; /* Navy color */
}

.tab-content p {
  font-size: 1rem;
}

.collegeadvisor-benefits-list li {
  margin-bottom: 5px;
}